$(document).ready(function(){
	if(!document.querySelector('.common-sign-in-area')
			&& !document.querySelector('.common-unregisterd-area')) return false;

	var sign = {
		el: document.getElementById('signinForm'), // form
		accountErrMsg: document.querySelector('.incorrect-account'),
		cookieName: 'LG5_RememberAccount',
		cookieCheckbox: null,
		accountMail: null,
		init: function(){
			sign.accountMail = $(sign.el).find('input.email').get(0);
			sign.cookieCheckbox = $(sign.el).find('input.remember-account').get(0);
			sign.addEvent();
			sign.getCookie();
		},
		getCookie: function(){
			var ac = getCookie(sign.cookieName);
			if(ac !== undefined) {
				sign.cookieCheckbox.checked = true;
				sign.accountMail.value = ac;
			}
		},
		bakeCookie: function(){
			if(sign.cookieCheckbox.checked) {
				// bake account cookie
				var ac = xssfilter($(sign.el).find('input.email').get(0).value);
				setCookie(sign.cookieName, ac);
			}else {
				removeCookie(sign.cookieName);
			}
		},
		beforeSubmit: function(e){
			$('#overlay').show();
			$('[name="signinFormTrigger"]').attr("disabled", true);
			e.preventDefault();
			// ajax validation
			if($(sign.el).checkValidation()) {
				// adobe
				adobeTrackEvent('sign-in-submit', {login_type : "id_password", page_event : {sign_in : true}});
				/*sign.bakeCookie();
				setTimeout(function() {
					sign.el.submit();
				}, 100);*/
				//Not need ajax.
				grecaptcha.enterprise.execute($("#sitKey").val(), {action: 'login'}).then(function(token) {
					$('#reCaptchaToken').val(token);
					
					var url = sign.el.getAttribute('data-url');
					if(!url){
						sign.bakeCookie();
				 		$(sign.accountErrMsg).collapse('hide');
				 		setTimeout(function() {
							sign.el.submit();
						}, 100);
					}else{
						var param = xssfilter($(sign.el).serialize());
						var methodType = $(sign.el).data('ajaxMethod');
						$('.incorrect-captcha').hide();
						$('.incorrect-account').hide();
						ajax.noCacheCall(url, param, 'json', function(data){
						 	if(!data.result) {
						 		if(data.statusCode == 407){
						 			location.reload();
						 		}else if(data.statusCode == 408){
						 			$('.incorrect-captcha').show();
						 			$('#customizedCaptcha_ReloadIcon').click();
									$('[name="signinFormTrigger"]').attr("disabled", false);
									$('#overlay').hide();
						 		}else if(data.statusCode == 406){
						 			if(data.returnCode == "MS.001.10"){
						 				var errorMessage = data.returnMessage;
						 				$('#modal-3rd-party .modal-form-area .error-msg').hide();
						 				$('#thirdIdType').val(data.idType);
						 				$('#thirdEmail').val($('input[name=dkdlel]').val());
				 						$('#modal-3rd-party').modal('show');
				 						$('#signinErrMsg').show();
						 				$('#fgpassErrMsg').hide();
				 						$('#3rdPartyMsg').text('').text(errorMessage);
						 			} else if(data.returnCode == "MS.001.03"){
										var aLinkUrl = $("#curDomain").val() + "/" + data.countryCode.toLowerCase();
										var message = "This email was used on an LG " + data.countryName + " account and cannot be used on LG USA until you first delete the <a href=\"" + aLinkUrl + "\" target=\"_blank\" style=\"text-decoration: underline;color: red;\">LG " + data.countryName + " account</a>";
										$('#modal-non-us #nonUsMessage').html('').html(message);
										$('#modal-non-us').modal('show');
						 			} else if(data.returnCode == "MS.001.01"){
										$('.incorrect-account').text('').text("The email address entered does not match any existing accounts. Please verify the email or create a new account.").show();
									} else if(data.returnCode == "MS.001.02"){
										$('.incorrect-account').text('').text("The Password is incorrect.").show();
									} else {
						 				$('.incorrect-account').text('').text("The Email or Password is incorrect.").show();
						 			}
									$('[name="signinFormTrigger"]').attr("disabled", false);
									$('#overlay').hide();
						 		}else if(data.returnMessage=='LG_Account_Block'){
						 			location.href = $('#blockAccount').val();
						 		}else{
									if(data.returnCode == "MS.001.01"){
										$('.incorrect-account').text('').text("The Email is incorrect.").show();
									} else if(data.returnCode == "MS.001.02"){
										$('.incorrect-account').text('').text("The Password is incorrect.").show();
									} else {
						 				$('.incorrect-account').text('').text("The Email or Password is incorrect.").show();
						 			}
									$('[name="signinFormTrigger"]').attr("disabled", false);
									$('#overlay').hide();
						 		}
						 	}else {
						 		if(data.thinqCase == "2" || data.thinqCase == "7"){
					 				var errorMessage = data.returnMessage;
					 				$('#modal-thinq .modal-form-area .error-msg').hide();
					 				$('#thinqIdType').val(data.idType);
					 				$('#thinqEmail').val($('input[name=dkdlel]').val());
			 						$('#modal-thinq').modal('show');
			 						$('#thinqSigninErrMsg').show();
					 				$('#thinqFgpassErrMsg').hide();
					 				$('#thinqIntenationalErrMsg').hide();
			 						$('#3rdPartyMsg').text('').text(errorMessage);
									$('[name="signinFormTrigger"]').attr("disabled", false);
									$('#overlay').hide();
				 				} else {
							 		if('Y'==data.newTermsFlag){
							 			setCookie('empSessionId', data.loginsessionid, true);
							 			setCookie('newTermsStr', data.newTermsStr, true);
							 			$('#modal_emp-policy span.modalEmail').html(sign.accountMail.value);
							 			$('#modal_emp-policy .modal-form-area .error-msg').hide();
							 			$('#modal_emp-policy').modal('show');
										$('[name="signinFormTrigger"]').attr("disabled", false);
										$('#overlay').hide();
							 		}else if('Y'==data.lgNewTermsFlag){
							 			//$('#modal_lg-policy span.modalEmail').html(sign.accountMail.value);
							 			$('#modal_lg-policy .modal-form-area .error-msg').hide();
							 			$('#modal_lg-policy').modal('show');
										$('[name="signinFormTrigger"]').attr("disabled", false);
										$('#overlay').hide();
							 		}else{
							 			sign.bakeCookie();
							 			$('.incorrect-account').hide();
							 			setTimeout(function() {
							 				sign.el.submit();
							 			}, 100);
							 		}
					 			}
						 	}
						 },methodType);
					}
				});
			} else {
				$('[name="signinFormTrigger"]').attr("disabled", false);
				$('#overlay').hide();
			}
		},
		addEvent: function(){
			$(sign.el).on({
				submit: sign.beforeSubmit
			});
			// $(sign.el.signinFormTrigger).on({
			// 	click: sign.beforeSubmit
			// });
		}
	};

	var fgpw = {
		el: document.getElementById('forgotForm'), // form
		modal: document.getElementById('find_my_password'), // modal popup
		accountMail: null,
		init: function(){
			fgpw.accountMail = $(fgpw.el).find('input.email').get(0);
			fgpw.addEvent();
		},
		beforeSubmit: function(e){
			e.preventDefault();
			// ajax validation
			if($(fgpw.el).checkValidation()) {
				
				grecaptcha.enterprise.execute($("#sitKey").val(), {action: 'login'}).then(function(token) {
					$('#forgotReCaptchaToken').val(token);

					$.ajax({
						url: fgpw.el.getAttribute("data-url"),
						type: 'post',
						data: {
							"email" : fgpw.accountMail.value
						},
						dataType:'json',
						beforeSend: function(w) {
							$('body').trigger('ajaxLoadBefore');
						},
						success: function (d) {
							$('body').trigger('ajaxLoadEnd');
							
							var statusCode = d.statusCode;
							if(200 == statusCode) {
								var idType = d.userIdType;
								var errorMessage = "Already in use by 3rd-Party Registeration:" + idType;
								$('#modal-3rd-party .modal-form-area .error-msg').hide();
				 				$('#thirdIdType').val(idType);
				 				$('#thirdEmail').val($('#forgotEmail').val());
		 						$('#modal-3rd-party').modal('show');
		 						$('#signinErrMsg').hide();
				 				$('#fgpassErrMsg').show();
		 						$('#3rdPartyMsg').text('').text(errorMessage);
							} else {
								fgpw.el.submit();
							}
						},
						error: function (request, status, error) {
							$('#forgotForm .find-password-area .error-msg .sysError').show();
							$('body').trigger('ajaxLoadEnd');
						}
					});
					
				});
			}
		},
		addEvent: function(){
			$(fgpw.el).on({
				submit: fgpw.beforeSubmit
			});
			// $(fgpw.el.forgotFormTrigger).on({
			// 	click: fgpw.beforeSubmit
			// });
		}
	};
	sign.init();
	fgpw.init();
	
	var $modal = $(document.querySelector('#modal_emp-policy')),
		$form = $modal.find('form');
	
	var validationCheck = function(e) {
		e.preventDefault();
		if($('#signUp-chk1').is(":checked")){
			$.ajax({
				url: $form.data('url'),
				type: 'post',
				data: {'empSessionId':getCookie('empSessionId'),'newTermsStr':getCookie('newTermsStr')},
				dataType:'json',
				beforeSend: function(w) {
					$('body').trigger('ajaxLoadBefore');
				},
				success: function (d) {
					$('#signinForm .btn-area [name=signinFormTrigger]').trigger('click');
					$('body').trigger('ajaxLoadEnd');
				},
				error: function (request, status, error) {
					$('#modal_emp-policy .modal-form-area .error-msg').show();
					$('#modal_emp-policy .modal-form-area .error-msg .invalid').show().siblings().hide();
					$('body').trigger('ajaxLoadEnd');
				}
			});
		}else{
			$('#modal_emp-policy .modal-form-area .error-msg').show();
			$('#modal_emp-policy .modal-form-area .error-msg .required').show().siblings().hide();
		}
	};
	
	
	$form.on('submit', validationCheck);
	
	
	var $lgModal = $(document.querySelector('#modal_lg-policy')),
	$lgPolicyForm = $lgModal.find('form');
	
	var lgValidationCheck = function(e) {
		e.preventDefault();
		if($('#modal_lg-policy #signUp-chk2').is(":checked")){
			$('body').trigger('ajaxLoadBefore');
			$(sign.el).attr('action',$lgPolicyForm.data('url'));
			$(sign.el).attr('data-url','');
			$(sign.el).submit();
		}else{
			$('#modal_lg-policy .modal-form-area .error-msg').show();
			$('#modal_lg-policy .modal-form-area .error-msg .required').show().siblings().hide();
		}
	};
	
	
	$lgPolicyForm.on('submit', lgValidationCheck);
	
	var thirdparty = {
		el: document.getElementById('thirdpartyForm'), // form
		init: function(){
			thirdparty.addEvent();
		},
		beforeSubmit: function(e){
			e.preventDefault();
			$('#modal-3rd-party .modal-form-area .error-msg').hide();
			grecaptcha.enterprise.execute($("#sitKey").val(), {action: 'sendThirdpartyCombineMail'}).then(function(token) {
				$('#thirdpartyReCaptchaToken').val(token);
				var idType = $("#thirdIdType").val();
				var email = $("#thirdEmail").val();
				if(idType != "" && email != "") {
//					$.ajax({
//						url: $thirdpartyForm.data('url'),
//						type: 'post',
//						data: {
//							"email" : email,
//							"idType" : idType
//						},
//						dataType:'json',
//						beforeSend: function(w) {
//							$('body').trigger('ajaxLoadBefore');
//							$('#modal-3rd-party .modal-form-area .error-msg').show();
//						},
//						success: function (d) {
//							$('body').trigger('ajaxLoadEnd');
//							$('#modal-3rd-party').modal('hide');
//						},
//						error: function (request, status, error) {
//							$('#modal-3rd-party .modal-form-area .error-msg').show();
//							$('#modal-3rd-party .modal-form-area .error-msg .invalid').show().siblings().hide();
//							$('body').trigger('ajaxLoadEnd');
//						}
//					});
					thirdparty.el.submit();
					
				} else {
					$('#modal-3rd-party .modal-form-area .error-msg').show();
					$('#modal-3rd-party .modal-form-area .error-msg .invalid').show().siblings().hide();
					$('body').trigger('ajaxLoadEnd');
				}
			});
		},
		addEvent: function(){
			$(thirdparty.el).on({
				submit: thirdparty.beforeSubmit
			});
		}
	};
	thirdparty.init();
	
	var thinqCombine = {
		el: document.getElementById('thinqForm'), // form
		init: function(){
			thinqCombine.addEvent();
		},
		beforeSubmit: function(e){
			e.preventDefault();
			$('body').trigger('ajaxLoadBefore');
			$('#modal-thinq .modal-form-area .error-msg').hide();
			grecaptcha.enterprise.execute($("#sitKey").val(), {action: 'sendThinqCombineMail'}).then(function(token) {
				$('#thinqReCaptchaToken').val(token);
				var idType = $("#thinqIdType").val();
				var email = $("#thinqEmail").val();
				if(idType != "" && email != "") {
					thinqCombine.el.submit();
				} else {
					$('#modal-thinq .modal-form-area .error-msg').show();
					$('#modal-thinq .modal-form-area .error-msg .invalid').show().siblings().hide();
					$('body').trigger('ajaxLoadEnd');
				}
			});
		},
		addEvent: function(){
			$(thinqCombine.el).on({
				submit: thinqCombine.beforeSubmit
			});
		}
	};
	thinqCombine.init();
});
